import { useObservableCallback, useObservableState } from "observable-hooks";
import { useMemo, useEffect, useRef } from "react";
import { debounceTime, distinctUntilChanged } from "rxjs";

type Rect = {
  width: number;
  height: number;
};

export const useResizeObserver = (ref: React.RefObject<HTMLElement>) => {
  const initialized = useRef(false);
  const [onChange, heightChange$] = useObservableCallback<Rect>((event$) =>
    event$.pipe(debounceTime(100), distinctUntilChanged()),
  );
  const { width, height } = useObservableState(heightChange$, { width: 0, height: 0 });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeObserver = useMemo(() => new ResizeObserver((e) => onChange(e[0]?.contentRect)), []);
  useEffect(() => {
    if (!ref.current) return;
    if (!initialized.current) {
      resizeObserver.observe(ref.current!);
    }
    initialized.current = true;
  });
  return { width, height };
};
